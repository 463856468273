import styled from 'styled-components';

export default styled.div`
  display: inline-block;
  padding-bottom: 0.2rem;
  padding-top: ${props => (props.inline ? '0' : '0.05rem')};
  color: ${props => (props.theme.black ? 'white' : 'black')};
  font-family: ${props => (props.small ? 'ffvGaramond' : 'ffvCorporateFat')};
  border-bottom: ${props =>
    props.small && !props.noUnderline
      ? `1px solid ${props.theme.black ? 'white' : 'black'}`
      : 'none'};
  background-image: ${props =>
    props.small
      ? 'none;'
      : `linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 49%,
          #f6ff88 50%
  );`}
  background-size: 200% 200%;
  background-position: 50% 15%;
  &:hover {
    cursor: ${props => (props.noPointer ? 'auto' : 'pointer')};
    background-position: 50% 100%;
    color: ${props =>
      props.small && !props.noHover
        ? 'gray'
        : props.theme.black
        ? 'white'
        : 'black'};
    ${props => (props.small ? 'border-color: gray' : '')}
  }
  transition: border-color 0.2s ease, background-position 0.2s ease, color 0.2s ease;
`;
