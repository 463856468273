import React from 'react';
import styled, { withTheme } from 'styled-components';

import { ScrollConsumer } from './ScrollProvider';
import IconContainer from './IconContainer';
import Logo from './Logo';
import Menu from './Menu';
import PathAnimation from './PathAnimation';
import Search from './Search';
import { isMobile } from 'react-device-detect';

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 5rem;
  top: 0px;
  left: 0px;
  text-align: center;
  color: ${props => (!props.theme.black ? 'black' : 'white')};
  font-size: 1.5rem;
  z-index: 91;
  & svg {
    fill: ${props => props.col};
    transition: fill 0.3s ease-in-out;
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: ${props => (props.scroll > 0 ? 1 : 0)};
  z-index: -1;
  background: ${props => (!props.theme.black ? 'white' : 'black')};
  transition: opacity 0.3s ease-in-out;
`;

const Burger = props => (
  <IconContainer onClick={props.onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </svg>
  </IconContainer>
);

const SearchIcon = props => (
  <IconContainer right="3rem" onClick={props.onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  </IconContainer>
);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOverLogo: false,
      animationDone: true,
      searchOpen: false,
      menuOpen: 'closed',
    };
    this.handleBurgerClick = this.handleBurgerClick.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleSearchClose = this.handleSearchClose.bind(this);
    this.findColor = this.findColor.bind(this);
    this.isDone = this.isDone.bind(this);
  }

  handleBurgerClick(e) {
    e.preventDefault();
    const prevState = this.state.menuOpen;
    switch (prevState) {
      case 'closed':
        this.setState({ menuOpen: 'open' });
        break;
      case 'close':
        this.setState({ menuOpen: 'open' });
        break;
      case 'open':
        this.setState({ menuOpen: 'close' });
        break;
      default:
        return;
    }
  }

  handleCloseClick(e) {
    this.setState({ menuOpen: 'close', searchOpen: false });
  }
  handleSearchClose(e) {
    this.setState({ searchOpen: false });
  }

  handleSearchClick(e) {
    e.preventDefault();
    this.setState({ searchOpen: true });
  }

  findColor(scroll) {
    if (this.props.startPage) {
      return scroll > 0 ? 'black' : 'white';
    }
    return !this.props.theme.black ? 'black' : 'white';
  }

  isDone(done) {
    if (!this.state.mouseOverLogo && done) {
      this.setState({ animationDone: true });
    }
  }

  render() {
    return (
      <>
        <ScrollConsumer>
          {scroll => (
            <Container col={this.findColor(scroll)}>
              <Background scroll={scroll} />
              <Logo
                col={this.findColor(scroll)}
                onMouseLeave={_ => this.setState({ mouseOverLogo: false })}
                onMouseEnter={_ =>
                  this.setState({ mouseOverLogo: true, animationDone: false })
                }
              />
              <SearchIcon
                col={this.findColor(scroll)}
                onClick={this.handleSearchClick}
              />
              <Burger
                col={this.findColor(scroll)}
                onClick={this.handleBurgerClick}
              />
            </Container>
          )}
        </ScrollConsumer>
        <Menu open={this.state.menuOpen} close={this.handleCloseClick} />
        {!this.state.animationDone && !isMobile && (
          <PathAnimation
            mouseOverLogo={this.state.mouseOverLogo}
            isDone={this.isDone}
          />
        )}
        <Search
          open={this.state.searchOpen}
          searchIndex={this.props.searchIndex}
          close={this.handleSearchClose}
        />
      </>
    );
  }
}

export default withTheme(Header);
