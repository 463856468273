import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import CenteredBlack from './CenteredBlack';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    height: 2.5rem;
    display: block;
  }
  & svg path {
    fill: ${props => props.col};
    transition: fill 0.3s ease-in-out;
  }
`;

export default props => (
  <Container col={props.col} style={props.style}>
    <Link to="/">
      <CenteredBlack
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      />
    </Link>
  </Container>
);
