import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  font-family: ffvGaramond, serif;
  text-align: center;
`;

export default props => {
  return (
    <Wrapper>&copy; flora&faunavisions, {new Date().getFullYear()}</Wrapper>
  );
};
