import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import React from 'react';

import Cookies from './Cookies';
import Footer from './Footer';
import GlobalStyle from './GlobalStyle';
import Header from './Header';
import Main from './Main';
import ScrollProvider from './ScrollProvider';
import gradient from '../assets/img/gradient.svg';

export default ({ searchIndex, children, background, startPage }) => (
  <ThemeProvider theme={{ black: background }}>
    <ScrollProvider>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover"
        />
        <title>flora&faunavisions</title>
      </Helmet>
      <GlobalStyle />
      <Header searchIndex={searchIndex} startPage={startPage} />
      <Main>{children}</Main>
      <Footer background={gradient} />
      <Cookies />
    </ScrollProvider>
  </ThemeProvider>
);
