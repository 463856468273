import { Link } from 'gatsby';
import { animated, Keyframes } from 'react-spring/renderprops';
import { config } from 'react-spring';
import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import styled, { withTheme } from 'styled-components';

import CloseIcon from './CloseIcon';
import Spacer from './Spacer';
import gradient from '../assets/img/gradient2.jpg';

const themeColor = props => (props.theme.black ? 'black' : 'white');
// const calcOpacity = (props, spring) =>
//   props.open ? (spring.opacity > 0 ? 1 : 0) : spring.opacity > 0 ? 0 : 1;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  background: ${props => (props.theme.black ? 'white' : 'black')};
  line-height: 2;
  height: 100vh;
  width: 33%;
  max-width: 350px;
  right: 0;
  text-align: left;
  z-index: 100;
  @media screen and (max-width: 700px) {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
`;

const MenuWrapper = animated(styled(Wrapper)`
  visibility: hidden;
  opacity: 0;
  overflow: scroll;
  color: ${themeColor};
`);

const Gradient = animated(styled(Wrapper)`
  background: url(${gradient});
  background-position: center center;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 100%;
`);

const GradientWrapper = animated(styled(Wrapper)`
  background: none;
  overflow: hidden;
  right: 0;
  pointer-events: none;
`);

const UL = styled.ul`
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0 5rem;
  margin-top: 5rem;
  @media screen and (max-width: 700px) {
    margin-top: 0;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const MenuItem = styled.li`
  color: ${themeColor};
  font-family: ${props => (props.sublink ? 'ffvGaramond' : 'ffvCorporateFat')};
  font-size: ${props => (props.sublink ? '1rem' : '1.5rem')};
  @media screen and (max-width: 700px) {
    font-size: ${props => (props.sublink ? '1.5rem' : '2.1rem')};
  }
`;

const Container = Keyframes.Spring({
  closed: {
    gradVisibility: 'hidden',
    gradOffset: '-100%',
    menuVisibility: 'hidden',
    menuOpacity: 0,
  },
  open: async next => {
    await next({
      gradVisibility: 'visible',
      menuVisibility: 'visible',
      immediate: true,
      menuOpacity: 0,
    });
    await next({
      from: { gradOffset: '-100%', menuOpacity: 0 },
      reset: true,
      gradOffset: '105%',
      menuOpacity: 1,
    });
    await next({ gradVisibility: 'hidden' });
  },
  close: async next => {
    await next({ gradVisibility: 'visible', immediate: true });
    await next({
      from: { gradOffset: '-100%', menuOpacity: 1 },
      reset: true,
      gradOffset: '105%',
      menuOpacity: 0,
    });
    await next({ gradVisibility: 'hidden', menuVisibility: 'hidden' });
  },
});

const gradientRef = React.createRef();

class Menu extends React.Component {
  handleClickOutside() {
    this.props.open === 'open' &&
      gradientRef.current.style.visibility === 'hidden' &&
      this.props.close();
  }
  render() {
    return (
      <>
        <Container state={this.props.open} native config={config.default}>
          {spring => (
            <>
              <MenuWrapper
                style={{
                  visibility: spring.menuVisibility,
                  opacity: spring.menuOpacity.interpolate(menuOpacity =>
                    menuOpacity <= 0.5 ? 0 : 1
                  ),
                }}
              >
                <CloseIcon
                  color={this.props.theme.black ? 'black' : 'white'}
                  onClick={() =>
                    gradientRef.current.style.visibility === 'hidden' &&
                    this.props.close()
                  }
                />
                <UL>
                  <Link to="/about" onClick={this.props.close}>
                    <MenuItem>About</MenuItem>
                  </Link>
                  <Link to="/work/all" onClick={this.props.close}>
                    <MenuItem>Work</MenuItem>
                  </Link>
                  <Link to="/diary" onClick={this.props.close}>
                    <MenuItem>News</MenuItem>
                  </Link>
                  <Link to="/awards" onClick={this.props.close}>
                    <MenuItem>Awards & Publications</MenuItem>
                  </Link> 
                  <Link to="/reels" onClick={this.props.close}>
                    <MenuItem>Reels</MenuItem>
                  </Link>
                  <Link to="/jobs" onClick={this.props.close}>
                    <MenuItem>Jobs</MenuItem>
                  </Link>
                  <Link to="/contact" onClick={this.props.close}>
                    <MenuItem>Contact</MenuItem>
                  </Link>
                  <Link to="/newsletter" onClick={this.props.close}>
                    <MenuItem>Newsletter</MenuItem>
                  </Link>
                  <Spacer space="2rem" />
                  <a
                    href="https://www.instagram.com/florafaunavisions/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MenuItem sublink>Instagram</MenuItem>
                  </a>
                  <a
                    href="https://www.facebook.com/florafaunavisionsgmbh/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MenuItem sublink>Facebook</MenuItem>
                  </a>
                  <a
                    href="https://vimeo.com/ffvberlin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MenuItem sublink>Vimeo</MenuItem>
                  </a>
                </UL>
              </MenuWrapper>
              <GradientWrapper
                ref={gradientRef}
                style={{
                  visibility: spring.gradVisibility,
                }}
              >
                <Gradient
                  style={{
                    left: spring.gradOffset,
                  }}
                />
              </GradientWrapper>
            </>
          )}
        </Container>
      </>
    );
  }
}

export default withTheme(enhanceWithClickOutside(Menu));
