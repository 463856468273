import React from 'react';
import throttle from 'lodash/throttle';
const ScrollContext = React.createContext(0);

export const ScrollConsumer = ScrollContext.Consumer;

export default class ScrollProvider extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = { scrollY: 0 };
  }

  componentDidMount() {
    this.throttled = throttle(this.handleScroll, 200);
    window.addEventListener('scroll', this.throttled);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttled);
  }

  handleScroll(e) {
    this.setState({ scrollY: window.pageYOffset });
  }

  render() {
    return (
      <ScrollContext.Provider value={this.state.scrollY}>
        {this.props.children}
      </ScrollContext.Provider>
    );
  }
}
