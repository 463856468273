import Fuse from 'fuse.js';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { navigate } from '@reach/router';

import CloseIcon from './CloseIcon';
import Logo from './Logo';
import SearchResults from './SearchResults';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: black;
  color: white;
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  opacity: ${props => (props.open ? 1 : 0)};
  overflow: hidden;
  z-index: 99;
`;

const SearchInput = styled.input`
  margin-top: 15%;
  outline: none;
  font-family: ffvCorporateFat;
  font-size: 3rem;
  color: grey;
  background: black;
  border: none;
  border-bottom: 1px solid grey;
  padding: 0.5rem;
  width: 67%;
  &:focus {
    color: white;
  }
  transition: color 0.5s ease-in-out;
  @media all and (max-width: 900px) {
    font-size: 1.5rem;
  }
`;

export default ({ searchIndex, open, close }) => {
  const [searchVal, setSearchVal] = useState('');
  const [searchOutput, setSearchOutput] = useState([]);
  const inputRef = useRef(null);

  const handleInput = e => {
    setSearchVal(e.target.value);
  };

  const [current, setCurrent] = useState(0);
  const handleKeyDown = e => {
    switch (e.keyCode) {
      case 40:
        setCurrent((current + 1) % searchOutput.length);
        break;

      case 38:
        setCurrent(current ? current - 1 : current);
        break;

      case 13:
        navigate(`/projects/${searchOutput[current].ID}`);
        close();
        break;

      default:
    }
  };

  useEffect(() => {
    if (!searchIndex) return;
    if (searchVal.length > 1) {
      const fuse = new Fuse(searchIndex, {
        shouldSort: true,
        threshold: 0.2,
        minMatchCharLength: 2,
        maxPatternLength: 50,
        distance: 200,
        keys: ['title', 'client', 'services', 'search_keywords'],
      });
      setSearchOutput(fuse.search(searchVal).slice(0, 5));
      setCurrent(0);
    }
  }, [searchIndex, searchVal]);

  useEffect(() => {
    inputRef.current.focus();
  }, [open]);

  return (
    <>
      <Wrapper open={open}>
        <Logo
          style={{ width: '100%', height: 'auto', paddingTop: '1.25rem' }}
          color="white"
        />
        <CloseIcon color={'white'} onClick={close} />
        <SearchInput
          name="searchInput"
          value={searchVal}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          placeholder="What are you looking for?"
          ref={inputRef}
        />
        <SearchResults results={searchOutput} active={current} />
      </Wrapper>
    </>
  );
};
