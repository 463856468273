import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background: #f6ff88;
  color: black;
  padding: 1.5rem;
  text-align: center;
  z-index: 9999;
`;
const Bold = styled.span`
  font-family: ffvCorporateFat;
`;
const Small = styled.span`
  font-family: ffvGaramond;
`;

const ButtonText = styled.span`
  font-family: ffvCorporateFat;
  border-bottom: 3px solid black;
  &:hover {
    cursor: pointer;
  }
`;
export default () => {
  const [consent, setConsent] = useState(true);
  useEffect(() => {
    setConsent(localStorage.getItem('ffvConsent'));
  }, []);

  const handleOkClick = e => {
    e.preventDefault();
    localStorage.setItem('ffvConsent', true);
    setConsent(true);
  };

  return consent ? null : (
    <Wrapper>
      <Grid container justify="center" spacing={8}>
        <Grid item xs={12} sm={3}>
          <Bold>We use cookies!</Bold>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Small>
            If you continue to use the website, we assume your consent.
          </Small>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ButtonText onClick={handleOkClick}>OK</ButtonText>
          &nbsp; &nbsp;
          <ButtonText>
            <Link to="/privacy">More Info</Link>
          </ButtonText>
        </Grid>
      </Grid>
    </Wrapper>
  );
};
