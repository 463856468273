import Lottie from 'react-lottie';
import React, { useEffect, useState } from 'react';
import random from 'lodash/random';
import styled from 'styled-components';

import * as data_5 from '../assets/animationData/data_5.json';
import * as data_1 from '../assets/animationData/data_1.json';
import * as data_2 from '../assets/animationData/data_2.json';
import * as data_3 from '../assets/animationData/data_3.json';
import * as data_4 from '../assets/animationData/data_4.json';
import * as data_6 from '../assets/animationData/data_6.json';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  z-index: 99999;
  opacity: ${props => (props.mouseOverLogo ? 1 : 0)};
  transition: opacity 0.3s ease-out;
`;

const animationData = [data_1, data_5, data_3, data_2, data_4, data_6];

export default ({ mouseOverLogo, isDone }) => {
  const [aniData, setAniData] = useState(
    random(0, animationData.length - 1, false)
  );

  const options = {
    animationData: animationData[aniData].default,
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };

  useEffect(() => {
    isDone(false);
    if (mouseOverLogo) {
      setAniData(random(0, animationData.length - 1, false));
    }
  }, [mouseOverLogo]);

  return (
    <Wrapper mouseOverLogo={mouseOverLogo}>
      <Lottie
        options={options}
        direction={mouseOverLogo ? 1 : -1}
        eventListeners={[
          { eventName: 'complete', callback: e => isDone(true) },
        ]}
      />
    </Wrapper>
  );
};
