import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  right: ${props => (props.right ? props.right : 0)};
  top: 0;
  padding: 0 2rem 0 0;
  font-size: 1.5;
  cursor: pointer;
`;

export default props => (
  <Container
    right={props.right}
    onClick={props.onClick}
    className={props.className}
  >
    {props.children}
  </Container>
);
