import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const List = styled.ul`
  list-style: none;
  width: 67%;
`;

const Item = styled.li`
  padding: 0.5rem;
  font-size: 1.2rem;
  color: ${props => (props.active ? 'white' : 'grey')};
`;

export default ({ results, active }) => {
  return (
    <List>
      {results.length ? (
        results.map((result, i) => (
          <Link key={result.title} to={`/projects/${result.ID}`}>
            <Item active={active === i}>{result.title}</Item>
          </Link>
        ))
      ) : (
        <Item>0 Results</Item>
      )}
    </List>
  );
};
