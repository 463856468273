import 'normalize.css';

import '../css/index.css';

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
		position: relative;
		width: 100%;
		height: 100%;
		min-height: 100vh;
		margin: 0;
		padding: 0;
		background: ${props => (props.theme.black ? 'black' : 'white')};
		font-family: ffvCorporateFat;
					font-weight: normal;
					padding-bottom: 7rem;
					font-smooth: auto;
		-webkit-font-smoothing: antialiased;
		// text-rendering:optimizeLegibility;
	}

	a {
		font-family: ffvCorporateFat;
		text-decoration: none;
		color: inherit;
		// color: ${props => (props.theme.black ? 'white' : 'black')}
	}

	ul {
		padding-inline-start: 0;
	}

  video {
    outline: none;
	}

	@media screen and (max-width: 650px) {
		:root {
			font-size: 12px;
		}
	}
`;
