import styled from 'styled-components';

export default styled.div`
  width: 80%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 5rem 0;
  @media all and (max-width: 1000px) {
    width: 90%;
  }
`;
